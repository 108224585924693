/**
 * Contain constants for job sources
 */

export enum JobSource {
  LINKUP = 'linkup',
  INDEED = 'indeed',
  API = 'api',
  LINKEDIN = 'linkedin',
  PORTAL = 'portal',
  ATS = 'ats',
  CONNECT2JOBS = 'connect2jobs',
  TELEFONICA = 'telefonica',
  TECNOEMPLEO = 'tecnoempleo',
  RANDSTAD = 'randstad',
  E2ETEST = 'e2etest',
}
